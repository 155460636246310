<template>
  <div class="main">
    <div class="left">
      <el-tree
        show-checkbox
        :data="treeData"
        node-key="id"
        default-expand-all
        @node-drag-end="handleDragEnd"
        draggable
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
      >
      </el-tree>
    </div>
    <div class="right">
      <el-table
        class="table-box"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="date" label="日期" sortable width="180"> </el-table-column>
        <el-table-column prop="name" label="姓名" sortable width="180"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      treeData: [
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
              children: [
                {
                  id: 11,
                  label: '三级 3-2-1',
                },
                {
                  id: 12,
                  label: '三级 3-2-2',
                },
                {
                  id: 13,
                  label: '三级 3-2-3',
                },
              ],
            },
          ],
        },
      ],
      tableData: [
        {
          id: 1,
          date: '2016-05-02',
          name: '王小虎1',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          id: 2,
          date: '2016-05-04',
          name: '王小虎2',
          address: '上海市普陀区金沙江路 1517 弄',
        },
        {
          id: 3,
          date: '2016-05-01',
          name: '王小虎3',
          address: '上海市普陀区金沙江路 1519 弄',
          children: [
            {
              id: 31,
              date: '2016-05-01',
              name: '王小虎3-1',
              address: '上海市普陀区金沙江路 1519 弄',
            },
            {
              id: 32,
              date: '2016-05-01',
              name: '王小虎3-2',
              address: '上海市普陀区金沙江路 1519 弄',
            },
          ],
        },
        {
          id: 4,
          date: '2016-05-03',
          name: '王小虎4',
          address: '上海市普陀区金沙江路 1516 弄',
        },
      ],
    }
  },
  methods: {
    // 拖拽结束后
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      /*
        思路：
        1.拖拽后获取拖拽的对象得到位移的对象，得到移动的距离，然后获取表格在页面中的四个顶点坐标点，判断是否移动到表格内。
        2. 移动到表格内还要区分表格是否有数据，和是否移动到表头
        3. 在判断具体移动到表格的那个元素上（考虑折叠的元素）
        4. 拿到目标元素，可以根据目标元素的具体内容在到table里面去找对应数据进行操作即可（需要对对原始数据进行数组平铺）

      */ 
      let els = document.querySelectorAll('.table-box .el-table__row')

      let rightBox = document.getElementsByClassName('table-box')[0]
      // 获取table的四个顶点
      let boxTop = rightBox.offsetTop
      let boxEnd = rightBox.offsetTop + rightBox.offsetHeight
      let boxLeft = rightBox.offsetLeft
      let boxRight = rightBox.offsetLeft + rightBox.offsetWidth

      // 获取拖拽的坐标
      let pointX = ev.x
      let pointY = ev.y
      // 判断是否进入表格
      if(pointX > boxLeft && pointX < boxRight && pointY > boxTop && pointY < boxEnd) {
        console.log('在表格内')
        if(els.length > 0) { // 表格有数据
          let header = document.getElementsByClassName('has-gutter')[0]
          let headerHeight = header.offsetHeight
          
          // let tableItemHeight = els[0].offsetHeight
          let dropHeight = pointY - boxTop
          console.log(dropHeight,'--dropHeight')
          if(dropHeight <= headerHeight) {
            console.log('拖拽到表头')
          } else {
            let tableItemHeightList = [] // 所有表格item的高度（第一个和折叠对象的高度为0）
            for(let i = 0; i < els.length ; i ++) {
              tableItemHeightList.push(els[i].offsetTop)
            }
            console.log(tableItemHeightList,'---tableItemHeightList')
            /*
              得到所有的表格高度
            */
           let index = 0
           let currentHeight = dropHeight - headerHeight
           console.log(currentHeight,'---currentHeight')
           for (let k = 0 ;k < tableItemHeightList.length; k++) {
             if(currentHeight <= tableItemHeightList[k]) {
              if(k !== 0 && tableItemHeightList[k - 1] !== 0) {
                index = k - 1
              } else {
                let cindex = k
                while((tableItemHeightList[cindex - 1]) === 0) {
                  cindex -= 1
                }
                index = cindex - 1
              }
              
              break
             }
           }
           let dropEl = els[index]
           console.log(dropEl,'---dropEl')
           // 已拿到当前选中的元素，可以更具元素里面的内容进行判断，后面再去table里面找到对应的数据进行增加操作即可
            
          }
        }
      }
      // console.log()
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.label === '二级 3-1') {
        return type !== 'inner'
      } else {
        return true
      }
    },
    allowDrag(draggingNode) {
      console.log(draggingNode, '---draggingNodeallowDrop')
      return draggingNode.data.label.indexOf('三级 3-2-2') === -1
    },
  },
}
</script>

<style scoped>
.main {
  display: flex;
}

.left {
  width: 500px;
  height: 500px;
  background-color: #bfa;
}

.right {
  width: 500px;
  height: 500px;
  background-color: pink;
}
</style>
